import {
  getHardwareMonitoringStatus,
  GetHardwareMonitoringStatusBody,
} from "@intelligentlilli/api-layer";
import { useCallback, useEffect, useState } from "react";
import { determineHardwareStatus } from "../Utils/newDeviceMonitoring/determineHardwareStatus";
import { useAppSelector } from "State/hooks";

/**
 * Custom hook to fetch the hardware monitoring status of a specific device.
 *

 * const { loading, error, data } = useGetHardwareStatus({ devicestring: "device123", serviceUserId: "user456" });
 */
export const useGetHardwareStatus = ({
  devicestring,
  serviceUserId,
}: {
  serviceUserId: string;
  devicestring?: string;
}) => {
  // redux state
  const server = useAppSelector((state) => state.session.server);

  // local state
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<GetHardwareMonitoringStatusBody | null>(
    null
  );

  const getHardwareStatus = useCallback(() => {
    if (!devicestring) return;
    setLoading(true);
    setError(false);
    getHardwareMonitoringStatus(server, serviceUserId, devicestring, "web")
      .then((res) => {
        if (res.ok && res.body) {
          setLoading(false);
          setData(res.body);
        } else {
          setLoading(false);
          setError(true);
          setData(null);
        }
      })
      .catch(() => {
        setLoading(false);
        setError(true);
        setData(null);
      });
  }, [devicestring, server, serviceUserId]);

  useEffect(() => {
    getHardwareStatus();
  }, [getHardwareStatus]);

  return {
    loading,
    error,
    data,
    getHardwareStatus,
    sensorStatus: determineHardwareStatus(data, !!devicestring),
  };
};
