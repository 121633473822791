import theme from "./../../../Styles/theme.scss";
import { GetHardwareMonitoringStatusBody } from "@intelligentlilli/api-layer";
import React from "react";
import { SensorStatus } from "./types";
import { SignalDisconnectedIcon } from "Styles/Icons/DesignSystem/SignalDisconnectedIcon";

/**
 * Determines the hardware status based on sensor data and installation status.
 * This is based on the new Hardware Monitoring endpoint which powers the SensorStatusTag and the Devices tab
 *
 * @param {GetHardwareMonitoringStatusBody} sensorsAndHub - An array of sensor objects, each containing sensor details.
 * @param {boolean} isInstalled - Flag indicating if the hardware kit is installed.
 * @returns {SensorStatus} An object representing the hardware status.
 * @returns {string} returns.variant - The status variant (e.g., "error", "offline", "warning", "online").
 * @returns {string} returns.label - A label describing the hardware status.
 * @returns {React.ReactNode} returns.leftIcon - Whether an icon should be displayed on the left.
 *
 */

export const determineHardwareStatus = (
  sensorsAndHub: GetHardwareMonitoringStatusBody,
  isInstalled: boolean
): SensorStatus => {
  if (!isInstalled) {
    return {
      variant: "error",
      label: "Kit not installed",
    };
  }
  if (!sensorsAndHub || sensorsAndHub.length === 0)
    return {
      variant: "error",
      label: "Sensor status unavailable",
      leftIcon: <SignalDisconnectedIcon colour={theme.neutral5} width="15px" />,
    };

  const sensorsExcludingHub = sensorsAndHub.filter(
    (sensor) => sensor.child_device_internal_id !== "hub"
  );

  const downSensorsCount = sensorsExcludingHub.filter(
    (sensor) => sensor.current_status !== "up"
  ).length;

  const allSensorsAndHubDown = sensorsAndHub.every(
    (sensor) => sensor.current_status === "down"
  );

  if (allSensorsAndHubDown) {
    return {
      variant: "offline",
      label: "Offline - Click to fix",
      leftIcon: <SignalDisconnectedIcon colour={theme.risk3} width="18px" />,
    };
  } else if (downSensorsCount >= 1) {
    return {
      variant: "warning",
      label: "Partially online",
    };
  } else {
    return { label: "Online", variant: "online" };
  }
};
